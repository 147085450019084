:root {
  --theme-major-color: #ff6a00;
  --theme-always-highlight: #3737be;
}

.primary-color {
  color: var(--theme-major-color);
}

.always-highlight-color {
  color: VAR(--theme-always-highlight);
}

.hide {
  display: none;
}

#root,
body {
  height: 100vh;
  overflow-x: hidden;
}

@media only screen and (max-width: 767px) {
  .card-product-list .info-aside {
    border-left: 0px;
  }
}

.hideScrollBarX {
  overflow-x: auto;
  scrollbar-width: none;
}
.hideScrollBarX::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.widget-header .active {
  color: red;
}

.widget-header .active i {
  color: red;
}

.force-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cursor-pointer {
  cursor: pointer;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.invert-carousel-next-icon {
  .carousel-next-icon,
  .carousel-control-prev-icon {
    filter: invert(1);
  }

  .carousel-indicators li {
    filter: invert(1);
  }

  .carousel-indicators .active {
    filter: invert(1);
  }
}

.last-nmb > :last-child {
  margin-bottom: 0 !important;
}

.centered {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hand-cursor {
  cursor: pointer;
}

.dropdown-scroll {
  max-height: 280px;
  overflow-y: auto;
}
