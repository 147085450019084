.product-review {
  .total-like-user-main {
    a {
      display: inline-block;
      margin: 0 -17px 0 0;
      display: inline-block;
      margin: 0 -17px 0 0;
    }
  }
  .total-like {
    border: 1px solid;
    border-radius: 50px;
    display: inline-block;
    font-weight: 500;
    height: 34px;
    line-height: 33px;
    padding: 0 13px;
    vertical-align: top;
    border: 1px solid;
    border-radius: 50px;
    display: inline-block;
    font-weight: 500;
    height: 34px;
    line-height: 33px;
    padding: 0 13px;
    vertical-align: top;
  }
  .restaurant-detailed-ratings-and-reviews {
    hr {
      margin: 0 -24px;
      margin: 0 -24px;
    }
  }
  .graph-star-rating-header {
    .star-rating {
      font-size: 17px;
      font-size: 17px;
    }
  }
  .progress {
    background: #f2f4f8 none repeat scroll 0 0;
    border-radius: 0;
    height: 30px;
    background: #f2f4f8 none repeat scroll 0 0;
    border-radius: 0;
    height: 30px;
    background: #f2f4f8 none repeat scroll 0 0;
    border-radius: 0;
    height: 30px;
  }
  .rating-list {
    display: inline-flex;
    margin-bottom: 15px;
    width: 100%;
    display: inline-flex;
    margin-bottom: 15px;
    width: 100%;
  }
  .rating-list-left {
    height: 16px;
    line-height: 29px;
    width: 10%;
    height: 16px;
    line-height: 29px;
    width: 10%;
  }
  .rating-list-center {
    width: 80%;
    width: 80%;
  }
  .rating-list-right {
    line-height: 29px;
    text-align: right;
    width: 10%;
    line-height: 29px;
    text-align: right;
    width: 10%;
  }
  .restaurant-slider-pics {
    bottom: 0;
    font-size: 12px;
    left: 0;
    z-index: 999;
    padding: 0 10px;
    bottom: 0;
    font-size: 12px;
    left: 0;
    z-index: 999;
    padding: 0 10px;
  }
  .restaurant-slider-view-all {
    bottom: 15px;
    right: 15px;
    z-index: 999;
    bottom: 15px;
    right: 15px;
    z-index: 999;
  }
  .offer-dedicated-nav {
    .nav-link.active {
      border-color: #3868fb;
      color: #3868fb;
    }
    .nav-link {
      &:hover {
        border-color: #3868fb;
        color: #3868fb;
      }
      &:focus {
        border-color: #3868fb;
        color: #3868fb;
      }
      border-bottom: 2px solid #fff;
      color: #000000;
      padding: 16px 0;
      font-weight: 600;
    }
    .nav-item {
      margin: 0 37px 0 0;
    }
  }
  .restaurant-detailed-action-btn {
    margin-top: 12px;
  }
  .restaurant-detailed-header-right {
    .btn-success {
      border-radius: 3px;
      height: 45px;
      margin: -18px 0 18px;
      min-width: 130px;
      padding: 7px;
    }
  }
  .text-black {
    color: #000000;
  }
  .icon-overlap {
    bottom: -23px;
    font-size: 74px;
    opacity: 0.23;
    position: absolute;
    right: -32px;
  }
  .menu-list {
    img {
      width: 41px;
      height: 41px;
      object-fit: cover;
    }
  }
  .restaurant-detailed-header-left {
    img {
      width: 88px;
      height: 88px;
      border-radius: 3px;
      object-fit: cover;
      box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    }
  }
  .reviews-members {
    .media {
      .mr-3 {
        width: 56px;
        height: 56px;
        object-fit: cover;
      }
    }
  }
  .rounded-pill {
    border-radius: 50rem !important;
  }
  .total-like-user {
    border: 2px solid #fff;
    height: 34px;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    width: 34px;
  }
}
