.suggestions {
  position: absolute;
  top: 110%;

  width: 100%;
  z-index: 99999;

  border-radius: 0px 0px 10px 10px;
  background: white;

  .item {
    padding: 10px;
    cursor: pointer;

    &.active {
      background: #f7fafa;
    }
  }
}
